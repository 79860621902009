export function Component() {
  // const location = useLocation();
  // const [conn, setConn] = useState<OrgConnection | null>(null);
  // const [error, setError] = useState<string | null>(null);

  // useEffectOnce(() => {
  //   if (!SlackUtils.IsSlackInstalledState(location.state)) return;
  //   if (location.state.success) {
  //     setConn(location.state.connection);
  //   } else {
  //     setError(location.state.error);
  //   }
  // });

  return (
    <div className='w-full h-full text-white flex items-center justify-center'>
      {/* {conn && JSON.stringify(conn)}
      {error && <div className='text-red-002'>{error}</div>} */}
    </div>
  );
}
